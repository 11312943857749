import React, { useEffect, useState, useRef } from "react";
import MetaTags from "react-meta-tags";
import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  Table,
  Button,
  Nav,
  NavItem,
  NavLink,
  
} from "reactstrap";

import SweetAlert from "react-bootstrap-sweetalert";
import excelLogo from "../../assets/images/excel.png";

import classnames from "classnames";
import { Link, withRouter } from "react-router-dom";
import {  connect,  } from "react-redux";
import { leadsFilter } from "../../store/actions";
import Pagination from "../../components/Common/Pagination";
import Loader from "../../components/Common/Loader";


import Breadcrumbs from "../../components/Common/Breadcrumb";
import {
    getCarteraList,
  postExcelCartera,
  getCarteraFilter
  
} from "../../helpers/backend";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_green.css";
import "flatpickr/dist/l10n/es.js"; // Importa el idioma español
import axios from "axios"; // Asegúrate de importar axios
import CardsCartera from "./CardsCartera";
import FiltroInterior from "./FiltroInterior";
function Leads(props) {
  const URL = process.env.REACT_APP_API_URL;
 
    const [active, setActive] = useState(false)
  const [fechaInicio, setFechaInicio] = useState("");
  const [fechaFin, setFechaFin] = useState("");
  const [estado, setEstado] = useState("");
  const [agente, setAgente] = useState("");
  const [campana, setCampana] = useState("");
  const [tipoLead, setTipoLead] = useState("event");
  const [selectedFile, setSelectedFile] = useState(null);
  const [status, setStatus] = useState(null);
  const [alert, setAlert] = useState(false);
  const [data, setData] = useState("");
  const [loading, setLoading] = useState(false);
  const [changeUrl, setChangeUrl] = useState("");

  const [selectedDate, setSelectedDate] = useState(null);
  const [error, setError] = useState(null);
  const fileInputRef = useRef(null); 

 
  const handleFileReset = () => {
    setSelectedFile(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = ""; // Reiniciar el valor del input
    }
  };
  console.log(selectedFile)
 const [params, setParams] = useState({
    count: 0,
    next: null,
    previous: null,
    results: [],
  });
  const [filterCarga, setFilterCarga] = useState("");
  const [uniqueCargas, setUniqueCargas] = useState([]);
 

  useEffect(() => {
    const cargasUnicas = [
      ...new Set(params.results.map((item) => item.carga?.id).filter(Boolean))
    ];
    setUniqueCargas(cargasUnicas);
  }, [params.results]);

  const filteredResults = filterCarga
  ? params.results.filter((item) => item.carga?.id.toString() === filterCarga)
  : params.results;
  





 const onSubmit = async (e) => {
  e.preventDefault();

  // Construir la URL con los parámetros de filtro
  let newUrl = `?&fecha_inicio=${fechaInicio}&fecha_fin=${fechaFin}`;

  // Actualizar el estado de changeUrl con los nuevos parámetros
  setChangeUrl(newUrl);

  // Llamar a la API con la URL actualizada
  try {
    // Llamar a la API con la URL actualizada
    const response = await getCarteraFilter({ changeUrl: newUrl });

    // Asignar los datos obtenidos a setParams
    setParams({
      count: response.data.count,
      next: response.data.next,
      previous: response.data.previous,
      results: response.data.results,
    });
  } catch (error) {
    console.error("Error al obtener datos filtrados:", error);
  }
};
  
  // leadsFilter(
  //   `?&fecha_inicio=${fechaInicio}&fecha_fin=${fechaFin} `
  // )
  const resetSearch = () => {
    setActiveTab("1");
    getCarteraData()
    setFechaFin("");
    setFechaInicio("");
    setSelectedDate("");
  };

  
   const getCarteraData = async () => {
      setLoading(true);
      const response = await getCarteraList();
      setParams({
        count: response.data.count,
        next: response.data.next,
        previous: response.data.previous,
        results: response.data.results,
      });
      setLoading(false);
    };



  useEffect(() => {
    
   getCarteraData()
    // eslint-disable-next-line
  }, [estado]);

//   const cartera = useSelector ((state) => state);
  const [activeTab, setActiveTab] = useState("1");
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  const postExcel = async () => {
    setLoading(true);
    setError(null); // Reinicia el estado de error antes de la petición
  
    try {
      const formData = new FormData();
      formData.append("file", selectedFile);
  
      const response = await postExcelCartera(formData);
  
      setStatus(response.status);
      setData(response.data);
      
      if (response.data.errores && response.data.errores.length > 0) {
        setError(response.data.errores); // Guarda los errores recibidos
      }
    } catch (error) {
      setError(error.response?.data || { error: "Error desconocido al subir el archivo" });
      setStatus(error.response?.status || 500);
    }
  
    setLoading(false);
    setAlert(true);
  };
  const exportExcel = async () => {
    try {
      const url = `${URL}/leads/leads/exportar-excel/?fecha_inicio=${fechaInicio}&fecha_fin=${fechaFin}&estado=${estado}&agents=${agente}&campaign=${campana}&tipo_lead=${tipoLead}}`;
      const response = await axios.get(url, { responseType: "blob" });
      if (response && response.data) {
        console.log(response)
        const blob = new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const urlBlob = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = urlBlob;
        link.setAttribute("download", "Reporte Leads.xlsx"); // Nombre del archivo
        document.body.appendChild(link);
        link.click();

        // Limpiar el URL del blob
        window.URL.revokeObjectURL(urlBlob);
        document.body.removeChild(link);
      } else {
        console.error("No se recibieron datos para exportar.");
      }
    } catch (error) {
      console.error("Error al exportar el archivo:", error);
    }
  };
 
 

  const handleDateChange = (selectedDates) => {
    if (selectedDates.length === 2) {
      const [start, end] = selectedDates;
      setFechaInicio(start.toISOString().split("T")[0]); // Formato YYYY-MM-DD
      setFechaFin(end.toISOString().split("T")[0]); // Formato YYYY-MM-DD
      setSelectedDate(selectedDates);
    }
  };


  return (
    <div className="page-content">
      <MetaTags>
        <title>TrueContact | Carteras </title>
      </MetaTags>
      <Container fluid>
        <Breadcrumbs title="TrueContact" breadcrumbItem=" Leads " />
        {loading ? <Loader /> : null}
        {status === 201 && alert ? (
          <SweetAlert
            title="Hecho!"
            success
            timeout={2000}
            showConfirm={false}
            onConfirm={() => {
              setAlert(false);
              handleFileReset()
              getCarteraData();
            }}
          >
            {data.message}
          </SweetAlert>
        ) : null}
     {status !== 201 && alert ? (
  <SweetAlert
    title="Error al cargar el archivo"
    danger
    // timeout={3000}
    showConfirm={true}
    onConfirm={() => setAlert(false)}
  >
    {Array.isArray(data.errores) && data.errores.length > 0 ? (
      <ul>
        {data.errores.map((err, index) => (
          <li key={index}>{`Fila ${err.fila}: ${err.error}`}</li>
        ))}
      </ul>
    ) : (
      <p>{data.error || "Ocurrió un error desconocido."}</p>
    )}
  </SweetAlert>
) : null}
    
        {props.loading ? (
          <Loader />
        ) : (
          <div>
           
            <Card>
              <CardBody>
                <Row className="d-flex justify-content-center">
                  <Col lg={12}>
                    <Row>
                      <Col>
                        <Flatpickr
                          className="form-control d-block"
                          placeholder="Fecha"
                          options={{
                            mode: "range",
                            dateFormat: "Y-m-d",
                            locale: "es",
                          }}
                          onChange={handleDateChange}
                          value={selectedDate}
                        />
                      </Col>
                     
                      <Col>
                        <Button
                          color="warning"
                          className="btn-sm me-1 mt-1"
                          onClick={onSubmit}
                        >
                          <b>Consultar</b>
                        </Button>
                        <Button
                          color="danger"
                          className="btn-sm me-1 mt-1"
                          onClick={resetSearch}
                        >
                          <b>Borrar</b>
                        </Button>
                        <button
                          className="btn btn-sm btn-secondary  text-dark"
                          onClick={exportExcel}
                        >
                          <img
                            className="me-1"
                            alt=""
                            src={excelLogo}
                            height="20px"
                          />{" "}
                          <b>Exportar</b>
                        </button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </CardBody>
            </Card>

         
        
          
            <Card className="mx-auto  mt-3">
              <Nav tabs justified className="nav-tabs-custom" role="tablist">
               
                <NavItem>
                  <NavLink
                    to="#"
                    className={`p-2 ${classnames({
                      active: activeTab === "1",
                    })}`}
                  
                    style={{ cursor: "pointer" }}
                  >

                  <Row>
                  <FiltroInterior
                  setParams={setParams}
                  ></FiltroInterior>
                 
                  <Col className="d-flex justify-content-end mb-1">
               
                                       <Button onClick={() => setActive(true)}>
                                         <span
                                           className={`fas fa-align-justify fa-md ${active ? 'text-primary' : 'text-dark'}`}
                                         />
                                       </Button>{" "}
                                       <Button 
                                       onClick={() => setActive(false)}
                                       >
                                         <span
                                           className={`fas fa-grip-vertical fa-lg ${active ? 'text-dark' : 'text-primary'} ms-1`}
                                         />
                                       </Button>
                                     </Col>
                                     </Row> 
                  </NavLink>
                </NavItem>
              
              </Nav>
             
              {activeTab === "1" && (
                <>
                  <Col>
                    <div className="m-2">
                      <input
                        type="file"
                        id="excelFile"
                        ref={fileInputRef}
                        accept=".xls, .xlsx"
                        style={{ display: "none" }}
                        onChange={(event) =>
                          setSelectedFile(event.target.files[0])
                        }
                      />
                      <div className="d-flex align-items-center mb-1">
                        <Button
                          color="success"
                          className="me-2 btn-sm"
                          onClick={() => {
                            document.getElementById("excelFile").click();
                          }}
                        >
                          <span>
                            <img
                              className="me-1"
                              alt=""
                              src={excelLogo}
                              height="20px"
                            />
                          </span>
                          <b>Subir Carteras</b>
                        </Button>
                     
                      </div>
                      {selectedFile && (
                        <p className="font-size-10 ms-3 mb-1">
                          Archivo seleccionado: {selectedFile.name}
                        </p>
                      )}
                      {selectedFile && (
                        <>
                          <Button
                            color="success"
                            className="mb-2 ms-2 btn-sm"
                            onClick={() => {
                              setLoading(true);
                              postExcel();
                            }}
                          >
                            Generar
                          </Button>
                          <Button
                            color="danger"
                            className="mb-2 ms-2 btn-sm"
                            onClick={handleFileReset}
                          >
                            Borrar
                          </Button>
                        </>
                      )}
                    </div>
                  </Col>
                  {params.count === 0 ? (
                    <CardBody>
                      <h4 className="card-title">
                        No se encontraron resultados
                      </h4>
                    </CardBody>
                  ) : (
                    <CardBody>
                      {!selectedFile && (
                        <>

                    {active ? (
                          <div className="table-responsive mt-1">
                            <Table
                              className="table mb-4 text-center"
                              hover
                              responsive
                            >
                              <thead className="table-dark align-middle">
                                <tr>
                                <th>Código</th>
                                <th>Interior</th>
                                <th>Apartamento</th>
                                <th>Nombre</th>
                                <th>Anticipos</th>
                              
                                <th>Fecha Carga</th>
                                <th>
            Id de Carga
            <br />
            <select
              value={filterCarga}
              onChange={(e) => setFilterCarga(e.target.value)}
              style={{ width: "100px", fontSize: "12px", padding: "3px" }}
            >
              <option value="">Todos</option>
              {uniqueCargas.map((id) => (
                <option key={id} value={id}>
                  {id}
                </option>
              ))}
            </select>
          </th>

                                </tr>
                              </thead>
                              <tbody>
                              {filteredResults && Array.isArray(filteredResults) && filteredResults.length > 0 ? (
  filteredResults.map((item, index) => (
    <tr key={index}>
      <td>{item.codigo}</td>
      <td>{item.interior}</td>
      <td>{item.apto}</td>
      <td>{item.nombre}</td>
      <td>{item.anticipos}</td>
      <td>{item.fecha_carga}</td>
      <td>{item.carga ? item.carga.id : "-"}</td> {/* Agrega la fecha de carga */}
    </tr>
  ))
) : (
  <tr>
    <td colSpan="7" style={{ textAlign: "center" }}>No hay datos disponibles</td>
  </tr>
)}
</tbody>
                            </Table>
                          </div>

                    ) : 
                    <CardsCartera
                    filteredResults={filteredResults}
                    ></CardsCartera>
                    
                    }
                          <Pagination
                            count={params.count}
                            next={params.next}
                            previous={params.previous}
                            limit={30}
                            baseUrl="leads/leads/"
                            filterFunction={leadsFilter}
                          />
                        </>
                      )}
                    </CardBody>
                  )}
                </>
              )}
            
            </Card>
            <Row>
              <Col className="d-flex justify-content-center">
                <Link
                  className="btn btn-secondary text-dark mb-5 "
                  to="/dashboard"
                >
                  Volver
                </Link>
              </Col>
            </Row>
          </div>
        )}
      </Container>
    </div>
  );
}

const mapStateToProps = (state) => {
  const { error, loading, leads } = state.Leads;
  return { error, loading, leads };
};

export default withRouter(connect(mapStateToProps)(Leads));
