import React from 'react'
import styles from "../Contacts/Contacts.module.scss"
import { Card } from "reactstrap"
import { useHistory } from "react-router-dom";

function CardsCartera(props) {
  const history = useHistory()

  return (
    <div className={`text-center ${styles['card-container']}`}>

     
      {props.filteredResults &&
        Array.isArray(props.filteredResults) &&
        props.filteredResults.length > 0 ? (
        props.filteredResults.map((casos, idx) => ( // <--- Aquí quitamos ".results"
          <Card key={idx} className={styles.ticket} style={{ border: '0px' }}>
            <div>
              <div className={styles["ticket-header"]}>
                <p className={`mb-0 ${casos.name?.startsWith("Desplazamiento") ? "font-size-13" : "font-size-18"}`}>
                  <b>Codigo: {casos.codigo}</b>
                </p>
              </div>
              <div onClick={() => history.push(`/cartera/view/${casos.id}`)} className={styles["ticket-body"]} >
            
              <p className="font-size-12 text-dark d-flex justify-content-center"><b>Interior: </b> {casos.interior}</p>
              <p className="font-size-12 text-dark d-flex justify-content-center"><b>Apartamento: </b> {casos.apto}</p>
              <p className="font-size-12 text-dark d-flex justify-content-center"><b>Nombre: </b> {casos.nombre}</p>
              <p className="font-size-12 text-dark d-flex justify-content-center"><b>Anticipos: </b> {casos.anticipos}</p>
              <p className="font-size-12 text-dark d-flex justify-content-center"><b>Id de Carga: </b> {casos.carga ? casos.carga.id : ""}</p>
            </div>

            </div>
            {/* Botones de acción */}
         
          </Card>
        ))
      ) : (
        <p>No hay datos disponibles</p>
      )}
    </div>
  )
}

export default CardsCartera
