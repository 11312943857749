import React, { useEffect, useState} from "react"
import MetaTags from 'react-meta-tags';
import { 
  Card,
 
  Col,
  Container,
  Row,Nav, NavItem, NavLink, TabContent, TabPane, 
 } from "reactstrap"
 import classnames from 'classnames';
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import Loader from "../../components/Common/Loader"
import { getCarteraRead } from "../../helpers/backend";
import { useParams } from "react-router-dom";

import 'react-responsive-carousel/lib/styles/carousel.min.css';
import styles from "./CasosDetails.module.scss"
import Timeline from "./TimeLine";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const CasosDetails = (props) => {
    const id = useParams().id
 

    const [activeTab, setActiveTab] = useState('1');

const  [caso, setCaso] = useState ({});
console.log(caso)
const getCasoData = async () => {
    const response = await getCarteraRead(id);
    const data = response.data;
    setCaso(data)
}
const toggle = tab => {
  if (activeTab !== tab) setActiveTab(tab);
}

  useEffect(() => {
    getCasoData()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  function Status({ estado }) {
    const estados = {    
      EN_PAUSA: { className: "bg-info", text: "En Pausa" },
      PENDIENTE: { className: "bg-danger", text: "Pendiente" },
      SIN_ASIGNAR: { className: "bg-warning", text: "Sin Asignar" },
      EN_PROCESO: { className: "bg-pink", text: "En Proceso" },
      FINALIZADO: { className: "bg-success", text: "Finalizado" },
      ASIGNADO: { className: "bg-primary  ", text: "Asignado" }
    };
  
    const estadoActual = estados[estado];
  
    return (
      <div className={`badge  ${estadoActual.className} font-size-18`}>
        <strong>{estadoActual.text}</strong>
      </div>
    );
  }


  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title> TrueContact | Detalles del Caso </title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="TrueContact" breadcrumbItem=" Detalles del Caso" />

          {props.loading ? (
            <Loader />
          ) : (
            <div className="d-flex justify-content-center">              
                <Card className={styles.ticket} style={{border: '0px'}}>
                    {caso.estado?
                    <div style={{zIndex: '999'}}>
                    <b className="position-absolute top-0 end-0 m-2" ><Status estado={caso.estado} /></b>
                    </div> :null}
                    <div className="position-absolute top-0 start-0 m-2"  style={{zIndex: '999'}}>
                    <Link className="btn btn-secondary btn-sm text-dark" to="/casos">Volver</Link>
                    </div>
                    
                    <div className={styles["ticket-header"]}>
                      <h1><b>{caso.name}</b> </h1>
                      {caso.total_duracion ?
                      <p className="d-flex justify-content-center"><b>Tiempo de Finalizacion: </b> {caso.total_duracion}</p>
                      : null}
                    </div>
                    <div className={styles["ticket-body"]}>
                    <Card style={{width: '100%', height:'100%', border: '0'}}>
                    <Nav tabs justified className="nav-tabs-custom"  role="tablist">

                      <NavItem>
                          <NavLink
                              to="#"
                              className={`p-2 ${classnames({ active: activeTab === '1' })}`}
                              onClick={() => { toggle('1'); }}
                              style={{ cursor: 'pointer'}}>
                              <i className="fas fa-ticket-alt fa-lg"></i>
                              <span className="d-none d-sm-block">Detalles del Caso</span>
                          </NavLink>
                      </NavItem>                
                      <NavItem >
                          <NavLink
                              to="#"
                              className={`p-2 ${classnames({ active: activeTab === '2' })}`}
                              style={{ cursor: 'pointer'}}
                              onClick={() => { toggle('2'); }}>
                              <i className="fas fa-tasks fa-lg "></i>
                              <span className="d-none d-sm-block">Gestiones del Caso</span>
                          </NavLink>
                      </NavItem>
                     
                    </Nav>

                    <TabContent className="p-4" activeTab={activeTab}>  
                        <TabPane id="1" role="tabpanel" tabId="1">
                        <Row style={{ lineHeight: "0.5rem"}}>
                            <Col lg={6}>
                            <p><b>Fecha de Cargue: </b> {caso.fecha_carga}</p>
                            <p><b>Administracion: </b> {caso.administracion}</p>
                            <p><b>Anticipos: </b> {caso.anticipos}</p>
                            <p><b>Cliente(s): </b> {caso.nombre}  </p>
                        
                            </Col>
                          
                        </Row>
                     
                        </TabPane>                  
                        <TabPane id="2" role="tabpanel" tabId="2">
                          <Timeline gestiones={caso.gestiones} />
                        </TabPane>  
.
                    </TabContent> 
                    </Card>   
                    </div>
                </Card>
            </div>

          )}

        </Container>
      </div>
    </React.Fragment>
  )
}




export default CasosDetails;

