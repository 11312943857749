import React, { useState, useEffect } from "react";
import { Button } from "reactstrap";
import { getCarteraFilter } from "../../helpers/backend";


function FiltroInterior(props) {
  const [interior, setInterior] = useState([]);
  const [selectedInterior, setSelectedInterior] = useState(null);

  const handleClick = (interior) => {
    setSelectedInterior(interior);
    InteriorSubmit(interior);
  };
  
  const getInteriorData = async () => {
    let newUrl = `interiores`;
    try {
      // Llamar a la API con la URL actualizada
      const response = await getCarteraFilter({ changeUrl: newUrl });

      // Asignar los datos obtenidos a setParams

      setInterior(response.data.interiores);
    } catch (error) {
      console.error("Error al obtener datos filtrados:", error);
    }
  };

  useEffect(() => {
    getInteriorData();
  }, []);




const InteriorSubmit = async (valor) => {
 

let newUrl = `?interior=${valor}`;
  try {
    const response = await getCarteraFilter({ changeUrl: newUrl });
    props.setParams({
      count: response.data.count,
      next: response.data.next,
      previous: response.data.previous,
      results: response.data.results,
    });
  } catch (error) {
    console.error("Error al obtener datos filtrados:", error);
  }
};


  console.log(interior);

  return (
    <div>
      {Array.isArray(interior) &&
        interior.map((interior, idx) => (
          // <--- Aquí quitamos ".results"}
          <Button
  key={idx}
  onClick={() => handleClick(interior)}
  style={{
    marginLeft: "5px",
    background: selectedInterior === interior ? "darkgreen" : "rgb(131, 77, 38)", // Verde si está seleccionado
    color: "white",
    border: selectedInterior === interior ? "2px solid white" : "none",
    transition: "background 0.3s ease-in-out",
  }}
  onMouseEnter={(e) => e.target.style.background = selectedInterior === interior ? "darkgreen" : "rgb(160, 90, 45)"} 
  onMouseLeave={(e) => e.target.style.background = selectedInterior === interior ? "darkgreen" : "rgb(131, 77, 38)"}
>
  <i className="fas fa-building fa-lg" style={{ marginRight: "5px" }}></i>
  {interior}
</Button>
        
        
        ))}
    </div>
  );
}

export default FiltroInterior;
